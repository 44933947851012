import React from "react"

import { withPrismicUnpublishedPreview } from "gatsby-plugin-prismic-previews"
import { Helmet } from "react-helmet"

import { navigate } from "~/gatsby/navigate"
import useIsClient from "~/hooks/useIsClient"
import { FallbackRouteParams } from "~/types/global-types"
import { chefclubLocaleArray, getRedirectLanguage } from "~/utils/locales"

const NotFoundPage = () => {
  const isClient = useIsClient()

  const redirectToLocalizedNotFound = () => {
    const urlLang = getRedirectLanguage()

    navigate(`/${urlLang}/404`, { replace: true })
  }

  if (isClient) {
    const pathNameParts = window.location.pathname.split("/")
    if (!chefclubLocaleArray.includes(pathNameParts[1])) {
      redirectToLocalizedNotFound()
    }
  }

  const matchPathParams = {
    path: "/{locale}/___fallback/___fallback-route-404/",
    matchPath: "{locale}/*",
  }

  return (
    <Helmet>
      {/*
              Add to "window" variable the path to the csr component for matchPath purpose
              Stores the window.pagePath value in a different global variable and tricks gatsby into disabling client side routing by making this check never true:
              https://github.com/gatsbyjs/gatsby/blob/6d8f0bfdcc1a2fe2d07f03583b19ea7934b0ccf4/packages/gatsby/cache-dir/production-app.js#L144-L153
            */}
      <script>
        {`
             Object.defineProperty(window, 'pagePath', {
               get: function() { return undefined; },
               set: function(path) { window.chefclub__routeParams = {${Object.keys(
                 matchPathParams
               ).map(
                 (key: string) =>
                   `${key} : "${
                     (matchPathParams ?? {})[key as keyof FallbackRouteParams]
                   }"`
               )}}; }
             });
           `}
      </script>
    </Helmet>
  )
}

export default withPrismicUnpublishedPreview(NotFoundPage)
